.smallButton {
  height: 15px;
  width: 35px;
  margin: 10px;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.countContainer {
  align-self: center;
}

.removeFromCartBtn {
  background-color: var(--colorAttention);
  &:hover,
  &:focus {
    background-color: #c28200;
  }
}
